import PageComponent from '../../common/component/page-component';
import Cookies from 'js-cookie';


class AcceptTrackingCookie extends PageComponent {

	constructor({
		root,
		element,
		expirationDuration = 6,
		notifyModifier = 'visible',
		buttonAttr = 'accept-cookie-action',
	} = {}) {
		super({root: root, element: element});
		this.buttonAttr = buttonAttr;
		this.notifyModifier = notifyModifier;
		this.expirationDuration = expirationDuration;
	}

	prepare(element, data) {
		this.cookiesInfo = this.dataAttr(this.element).get('component');
		this.checkSetCookie();
		this.listeners.acceptCookie = this.events.on(this.element, 'click', this.onClick.bind(this));
	}

	checkSetCookie() {
		if (!Cookies.get(this.cookiesInfo)) {
			this.classList(this.element).add(this.notifyModifier);
		}
	}

	onClick() {
		Cookies.set(this.cookiesInfo, 1, {expires: this.expirationDuration});
		this.classList(this.element).remove(this.notifyModifier);
	}
}

export default AcceptTrackingCookie;
