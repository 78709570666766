// import {getScrollTop} from 'get-scroll';
import PageComponent from '../../common/component/page-component';


class Menu extends PageComponent {

	constructor({
		root,
		element
	} = {}) {
		super({root: root, element: element});
	}

	prepare(element) {
		this.listeners.menuToggle = this.events.on(document, this.dataSelector('menuToggle'), 'click', this.onMenuButtonClick.bind(this));
		this.listeners.sideMenuLogo = this.events.on(document, this.dataSelector('menuButton'), 'click', this.onSideMenuLogo.bind(this));
		this.listeners.menuClick = this.events.on(element, this.dataSelector('menuLink'), 'click', this.onMenuClick.bind(this), {capture: true});
	}

	injectNavigation(navigation) {
		this.navigation = navigation;
	}


	onMenuButtonClick() {
		this.contexts.toggle('menu');
	}

	onMenuClick(event, target) {
		const hasHash = target.href.search('#');
		if (hasHash > -1) {
			event.preventDefault();
			this.contexts.pop().then(() => {
				console.log(target.href);
				this.navigation.redirect(target.href);
			});
		} else {
			this.contexts.pop();
		}
	}

	onSideMenuLogo() {
		this.contexts.pop();
	}
}

export default Menu;
