import WldApp from './wld-app';


export default (di) => {
	di
		.setType({
			type: WldApp, name: 'WldApp', parent: 'App', params: {
				requiredStyles: ['default'],
				requiredFonts: [
					{
						name: 'Vogue Waald Flat Regular Med',
						options: {weight: 'normal'}
					},
					{
						name: 'Vogue Waald Flat Italic',
						options: {fontStyle: 'italic'}
					},
					{
						name: 'Plantin Regular',
						options: {weight: 'normal'}
					},
					{
						name: 'Plantin Italic',
						options: {fontStyle: 'italic'}
					}
				]
			}, setters: {
				injectPageSlots: di.lazyGet('page/slots'),
				injectNavigationTracker: di.lazyNew('GaGtagNavigationTracker'),
				injectCurrentLinkManager: di.lazyGet('navigation/currentLinkManager'),
				injectHashScroller: di.lazyNew('HashScroller'),
				// injectHighContrast: di.lazyGet('accessibility/highContrast')
			}, postCreateCalls: [(app) => {
				const transitionRouter = di.get('router/pageTransitionRouter');
				const loaderRouter = di.get('router/pageLoaderRouter');

				transitionRouter
					// fallback to default transition
					.append(transitionRouter.newRule().always().then((subject, matches, request) => {
						// console.log('match default page transition');
						request.transition = 'default';
						return subject;
					}).setLast()
					)
					;

				loaderRouter
					// fallback to server side rendered page
					.append(loaderRouter.newRule().always().then((subject, matches, request) => {
						// console.log('match default page loader');
						request.loader = 'serverRendered';
						return subject;
					}).setLast()
					)
					;
			}]
		})

		.set('app', di.lazyNew('WldApp'))
		;
};
