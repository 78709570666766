import Menu from './menu';
import Logo from './logo';



export default (di) => {
	di.setType({type: Menu, name: 'Menu', parent: 'PageComponent', setters: {
		injectNavigation: di.lazyGet('navigation/navigation')
	}});
	di.setType({type: Logo, name: 'Logo', parent: 'PageComponent'});
};
