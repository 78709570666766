import domMixin from '../dom/dom-mixin';

/*eslint-disable piggyback/no-restricted-global-extend*/
// const ga = window.ga;

class NavigationTracker extends domMixin() {

    constructor({enabled = true, trackEventEnabled = false, eventDataName = 'trackerEventData'}) {
		super();
		this.enabled = enabled;
		this.trackEventEnabled = trackEventEnabled;
		this.eventDataName = eventDataName;
		this.eventListeners = {};
	}


	enableAutoTrackEvents(events, options = {}) {
		options = Object.assign({
			name: null,
			selector: false,
			dataAttribute: this.eventDataName,
			dataProvider: null,
			capture: false
		}, options);
		const params = [document];
		if (options.selector) {
			params.push(options.selector);
		}
		params.push(events);
		params.push((event, target) => this.onAutoTrackEvent(event, target, options));
		params.push({capture: options.capture});
		const listener = this.events.on(...params);
		if (options.name) {
			this.eventListeners[options.name] = listener;
		}
		return this;
	}


	disableAutoTrackEvents(name) {
		if (name in this.eventListeners) {
			this.eventListeners[name].destroy();
			delete this.eventListeners[name];
		}
		return this;
	}


	// to be overriden
	init() {
		this.events.on(document, 'history:pagechange', this.onChange.bind(this));
	}

	// to be overriden
	doTrack(url, title) {}

	// to be overriden
	doTrackEvent(category, action = null, name = null, value = null) {}


	track(url, title) {
		if (this.enabled) {
			this.doTrack(url, title);
		} else {
			console.log('page change:', url, title);
		}
		return this;
	}


	trackEvent(category, action = null, name = null, value = null) {
		if (this.trackEventEnabled) {
			this.doTrackEvent(category, action, name, value);
		} else {
			console.log('tracking event', category, action, name, value);
		}
		return this;
	}


	onChange(event) {
		this.track(event.detail.url, event.detail.title);
	}


	onAutoTrackEvent(event, target, options) {
		let data = null;
		if (options.dataProvider) {
			data = options.dataProvider(event, target, options);
		} else if (options.dataAttribute in event.detail) {
			data = event.detail[options.dataAttribute];
		} else {
			data = this.dataAttr(target).get(options.dataAttribute, null);
		}
		if (data) {
			try {
				this.trackEvent(
					data.category,
					('action' in data ? data.action : null),
					('name' in data ? data.name : null),
					('value' in data ? data.value : null)
				);
			} catch(e) {
				console.log('unable to track the event on', target);
				console.error(e);
			}
		} else {
			console.log('no data found for event tracking');
		}
	}
}


export default NavigationTracker;
