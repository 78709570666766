import 'matchmedia-polyfill';
import Context from '../../common/context/context';


class MenuContext extends Context {

	constructor({
		name,
		root,
		mediaQuery,
		popOnKey = 'esc',
		openClass = 'open',
		inClass = 'active',
		fixedClass = 'fixed',
		swapModifier = 'swap',
		moveModifier = 'moved',
		positionModifier = 'move',
		visibleModifier = 'visible',
		expandModifier = 'expanded',
		scrollModifier = 'menuNoScroll',
		colorModifier = 'backgroundColor',
		svgColorModifier = 'invertedSvgColorSideMenu'
	}) {
		super({
			name: name,
			root: root,
			popOnKey: popOnKey
		});
		this.colorModifier = colorModifier;
		this.moveModifier = moveModifier;
		this.swapModifier = swapModifier;
		this.expandModifier = expandModifier;
		this.scrollModifier = scrollModifier;
		this.visibileModifier = visibleModifier;
		this.svgColorModifier = svgColorModifier;
		this.positionModifier = positionModifier;
	}

	setEvents() {
		this.counter = 1;
		this.open = false;
		this.ready = true;
		this.hasObserver = false;
		this.animationDuration = 500;
		this.headerIntersected = false;
		this.element = this.getElement();
		this.loopAnimationDuration = 200;
		this.events.on(document, 'isIntersecting', this.updateStatus.bind(this));
		this.events.on(document, 'hasObserver', this.updateStatus.bind(this));
		this.events.on(document, 'section:change', this.updateElements.bind(this));
		this.moveableBody = document.querySelector(this.dataSelector('movable-body'));
		this.headerClass = document.querySelector(this.dataSelector('header-toggle'));
		this.iconsButton = document.querySelectorAll(this.dataSelector('menuToggle'));
		this.iconClass = document.querySelectorAll(this.dataSelector('sideMenu-color-swap'));
	}

	updateElements(events, target, options) {
		if (options.name === 'mainMenu') {
			this.headerClass = document.querySelector(this.dataSelector('header-toggle'));
			this.iconClass = document.querySelectorAll(this.dataSelector('sideMenu-color-swap'));
		}
	}

	updateStatus(event) {
		if (event.detail) {
			this.isIntersecting = event.detail.isIntersecting;
		}
		if (event.detail) {
			this.hasObserver = event.detail.hasObserver;
		}
	}

	switchIn(otherContext) {
		this.moveClasses();
		this.removeBackground();
	}

	switchOut(otherContext) {
		this.moveClasses();
		if (!this.isIntersecting && typeof(this.isIntersecting) !== 'undefined' || this.hasObserver) {
			this.addBackground();
		}
		this.clean();
	}

	addBackground() {
		this.classList(this.headerClass).add(this.colorModifier);
	}

	removeBackground() {
		this.classList(this.headerClass).remove(this.colorModifier);
	}

	preventOverflow() {
		this.classList(this.moveableBody).toggle(this.scrollModifier);
	}

	moveClasses() {
		this.preventOverflow();
		this.checkIconModifierExists();
		this.swapIcons();
		this.classList(this.moveableBody).toggle(this.positionModifier);
		this.classList(this.element).toggle(this.positionModifier);
	}

	swapIcons() {
		for (const icon of this.iconsButton) {
			const hasClass = this.classList(icon).contains(this.swapModifier);
			if (hasClass) {
				this.classList(icon).remove(this.swapModifier);
			} else {
				this.classList(icon).add(this.swapModifier);
			}
		}
	}


	checkIconModifierExists() {
		for (let i = 0; i < this.iconClass.length; i++) {
			const ModifierSvgExists = this.classList(this.iconClass[i]).contains(this.svgColorModifier);
			if (!ModifierSvgExists) {
				this.classList(this.iconClass[i]).add(this.svgColorModifier);
			} else {
				this.classList(this.iconClass[i]).remove(this.svgColorModifier);
			}
		}
	}

	clean() {
		clearTimeout(this.delayedTimer);
		clearTimeout(this.openTimer);
	}
}

export default MenuContext;
