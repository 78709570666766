import DefaultPage from './default-page';

import DefaultPageTransition from './default-page-transition';


export default (di) => {
	di
		.setType({
			type: DefaultPage, name: 'DefaultPage', parent: 'Page', setters: {
				injectIntersectionTriggers: di.lazyNew('IntersectionTriggers'),
				// injectParallaxBackgrounds: di.lazyNew('ParallaxBackgrounds')
			}
		})

		.setType({type: DefaultPageTransition, name: 'DefaultPageTransition', parent: 'PageTransition'})
		;
};
