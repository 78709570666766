import onTransitionEnd from './transition-end';

const domMixin = (Base = null) => class extends Base {

	injectEvents(events) {
		this.events = events;
	}


	injectDataAttrParser(dataAttrParser) {
		this.dataAttrParser = dataAttrParser;
	}


	injectDataAttrFactory(dataAttrFactory) {
		this.dataAttrFactory = dataAttrFactory;
	}


	injectClassListParser(classListParser) {
		this.classListParser = classListParser;
	}


	injectClassListFactory(classListFactory) {
		this.classListFactory = classListFactory;
	}


	injectThreeStateTransitionFactory(threeStateTransition) {
		this.threeStateTransitionFactory = threeStateTransition;
	}


	classList(element = null, prefix = undefined) {
		return this.classListFactory({element: element, prefix: prefix});
	}


	dataAttr(element = null, defaults = {}, prefix = undefined) {
		return this.dataAttrFactory({element: element, defaults: defaults, prefix: prefix});
	}


	dataSelector(name, value = undefined, prefix = undefined) {
		return this.dataAttrParser.getSelector(name, value, prefix);
	}


	classSelector(name, prefix = undefined) {
		return this.classListParser.getSelector(name, prefix);
	}


	threeStateTransition(element) {
		return this.threeStateTransitionFactory(element);
	}


	onTransitionEnd(element, ...params) {
		return onTransitionEnd(element, ...params);
	}

};


export default domMixin;
