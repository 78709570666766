import domMixin from '../dom/dom-mixin';
import pageConstants from './_constants';


class SectionSlot extends domMixin() {

    constructor({name, root}) {
		super();
		this.name = name;
		this.root = root;
		this.pageBound = false;
		this.elements = null;
    }


    getName() {
        return this.name;
    }


    getElements() {
        if (this.elements === null) {
            this.elements = document.querySelectorAll(this.dataSelector(pageConstants.sectionSlotAttribute, this.name));
        }
        return this.elements;
    }


	fill(content, pageBound = false) {
		const elements = this.getElements();

		for (const element of elements) {
			if (content instanceof Element) {
				element.innerHTML = '';
				element.appendChild(content);
			} else {
				element.innerHTML = content;
			}

			this.events.trigger(element, 'section:change', {name: this.name, content: content, slot: this});
		}
		this.pageBound = pageBound;
		return this;
	}


	empty() {
		for (const element of this.getElements()) {
			element.innerHTML = '';
		}
	}


	isPageBound() {
		return this.pageBound;
	}

}


export default SectionSlot;
