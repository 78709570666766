import {TweenMax} from 'gsap';
import {getScrollTop} from 'get-scroll';
import 'intersection-observer';
import {getViewportHeight} from '../utils/size';
import domMixin from '../dom/dom-mixin';


class ParallaxBackgrounds extends domMixin() {

	constructor({
		sizeRatio = 1.15,
		intersectionMargin = '20px',
	} = {}) {
		super();
		this.sizeRatio = sizeRatio;
		this.intersectionMargin = intersectionMargin;
	}


	init(element, itemSelectors) {
		this.itemSelectors = itemSelectors;
		this.items = new Map();
		this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this), {
			rootMargin: `${this.intersectionMargin} 0px ${this.intersectionMargin} 0px`,
			threshold: [0]
		});

		for (const selector of this.itemSelectors) {
			const itemSelector = selector[0];
			const bgSelector = selector[1];
			const items = element.querySelectorAll(itemSelector);
			for (const item of items) {
				const bg = item.querySelector(bgSelector);
				if (!bg) {
					continue;
				}
				this.intersectionObserver.observe(item);
				this.items.set(item, {
					item: item,
					bg: bg,
					top: 0,
					height: 0,
					diff: 0,
					visible: true
				});
			}
		}
		if (this.items.size) {
			this.updateGeometry(true);
			this.resizeListener = this.events.on(window, 'window:resize', this.onResize.bind(this));
			this.scrollListener = this.events.on(window, 'window:scroll', this.onScroll.bind(this));
			requestAnimationFrame(() => this.updateGeometry(true));
		}
	}


	destroy() {
		if (this.items.size) {
			this.resizeListener.destroy();
			this.scrollListener.destroy();
		}
		this.intersectionObserver.disconnect();
	}


	onScroll(event) {
		this.updatePositions();
	}


	onResize(event) {
		this.updateGeometry();
	}


	onIntersection(records) {
		const offset = getScrollTop();
		for (const record of records) {
			if (this.items.has(record.target)) {
				const entry = this.items.get(record.target);
				const rect = record.target.getBoundingClientRect();
				entry.top = rect.top + offset;
				entry.height = rect.height;
				const bgHeight = rect.height * this.sizeRatio;
				entry.diff = bgHeight - entry.height;
				entry.visible = record.isIntersecting;
				this.classList(record.target).toggle('insideViewport', record.isIntersecting);
			}
		}
	}


	updatePositions(force = false) {
		const scrollTop = getScrollTop();
		for (const entry of this.items.values()) {
			if (entry.visible || force) {
				const top = entry.top - scrollTop;
				if (top >= -entry.height && top <= this.vHeight) {
					// y = m * x
					const m = -entry.diff / this.vHeight;
					const y = Math.round(m * top);
					TweenMax.set(entry.bg, {
						x: '-50%',
						y: y + 'px',
						force3D: true
					});
				}
			}
		}
	}


	updateGeometry(force = false) {
		const offset = getScrollTop();
		this.vHeight = getViewportHeight();
		for (const [item, entry] of this.items) {
			const rect = item.getBoundingClientRect();
			entry.top = rect.top + offset;
			entry.height = rect.height;
			const bgHeight = rect.height * this.sizeRatio;
			entry.diff = bgHeight - entry.height;
			TweenMax.set(entry.bg, {
				width: this.sizeRatio * rect.width + 'px',
				height: bgHeight + 'px',
				x: '-50%',
			});
		}
		this.updatePositions(force);
	}

}


export default ParallaxBackgrounds;

