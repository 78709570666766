import domMixin from '../dom/dom-mixin';


class SwapTransition extends domMixin() {

	execute(outElement, inElement) {
		return Promise.resolve(outElement, inElement);
	}


}


export default SwapTransition;
