import AsyncVideo from './async-video';


const config = (di) => {
	di
		.setType({type: AsyncVideo, name: 'AsyncVideo', parent: 'PageComponent'})
	;
};


export default config;
