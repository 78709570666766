import PageComponent from '../../common/component/page-component';
import {
	TweenMax
} from 'gsap';
import {
	getScrollTop
} from 'get-scroll';


class HeaderHandler extends PageComponent {

	constructor({
		root,
		events,
		element,
		moveModifier = 'move',
		fixModifier = 'fixed',
		stickyModifier = 'sticky',
		showMenuModifier = 'showMenu',
		colorModifier = 'backgroundColor',
		svgColorModifier = 'invertedSvgColorIntersection'
	} = {}) {
		super({
			root: root,
			element: element
		});
		this.events = events;
		this.element = element;
		this.isIntersecting = true;
		this.noObserver = false;
		this.headerClass = element;
		this.timeoutDuration = 1000;
		this.headerOutersected = false;
		this.hasBackground = false;
		this.fixModifier = fixModifier;
		this.moveModifier = moveModifier;
		this.colorModifier = colorModifier;
		this.contactLineOutersected = false;
		this.stickyModifier = stickyModifier;
		this.showMenuModifier = showMenuModifier;
		this.svgColorModifier = svgColorModifier;
		this.timer = '';
	}

	prepare() {
		this.events.on(document, 'pageChange:done', this.clear.bind(this));
		this.events.on(document, 'history:pagechange', this.updateElements.bind(this));
		this.updateElements();
	}

	// start(first) {
	// 	console.log('stat');
	// 	this.updateElements();
	// 	this.classList(this.headerClass).add(this.colorModifier);
	// }

	updateElements() {
		this.headerClass = document.querySelector(this.dataSelector('headerToggle'));
		this.isContentPage = document.querySelector(this.dataSelector('contentPage'));
		this.iconClass = document.querySelectorAll(this.dataSelector('iconBaseColor'));
		this.observedClass = document.querySelector(this.dataSelector('headerObserved'));
		this.observedContactLine = document.querySelector(this.dataSelector('contactLineObserved'));
		this.scrollListener = this.events.on(window, 'window:scroll window:resize', this.onScroll.bind(this));
		this.classList(this.headerClass).remove(this.colorModifier);
		this.observerInit();

		if (this.observedClass === null && this.observedContactLine === null) {
			this.noObserver = true;
			this.addColors();
		} else {
			this.noObserver = false;
		}
	}

	onScroll(event) {
		if (this.observedClass === null) {
			this.events.trigger(document, 'hasObserver', {
				hasObserver: this.hasBackground
			});
		}
		this.headerHeight = this.element.offsetHeight;
		const scrollTop = getScrollTop();
		const delta = scrollTop - this.lastScrollTop;
		const direction = delta < 0 ? -1 : 1;

		if (Math.abs(delta) < 15) {
			return;
		}

		this.hasFixModifier = this.classList(this.element).contains(this.fixModifier);


		if (scrollTop > this.headerHeight) {
			if (window.location.pathname !== '/') {
				this.classList(this.headerClass).add(this.colorModifier);
				this.hasBackground = true;
				if (!this.noObserver) {
					this.addColors();
				}
			}
			this.classList(this.headerClass).add(this.fixModifier);
		} else {
			if (window.location.pathname !== '/') {
				this.classList(this.headerClass).remove(this.colorModifier);
				this.hasBackground = false;
				if (!this.noObserver) {
					this.removeColors();
				}
			}
			this.classList(this.headerClass).remove(this.fixModifier);
		}

		if (this.hasFixModifier) {
			if (scrollTop > this.headerHeight) {
				if (this.contactLineOutersected && this.observedContactLine) {
					TweenMax.set(this.observedContactLine, {
						top: this.headerHeight
					});
				}
				this.classList(this.headerClass).toggle(this.showMenuModifier, direction < 0);
			}

			if (direction === 1 || direction > 0) {
				if (this.contactLineOutersected && this.observedContactLine) {
					TweenMax.set(this.observedContactLine, {
						top: 0
					});
				}
			}
		}
		this.lastScrollTop = getScrollTop();
	}

	observerInit() {
		if (this.observedClass !== null || this.observedContactLine !== null) {
			const options = {
				threshold: 1.0
			};

			this.contactIntersectionObserver = new IntersectionObserver(this.onContactIntersection.bind(this), options);
			this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this));


			if (this.observedContactLine) {
				this.contactIntersectionObserver.observe(this.observedContactLine);
			}
			if (this.observedClass) {
				this.intersectionObserver.observe(this.observedClass);
			}
		}
	}

	onIntersection(entries) {
		for (const entry of entries) {
			if (entry.isIntersecting === false) {
				this.isIntersecting = entry.isIntersecting;
				this.events.trigger(document, 'isIntersecting', {
					isIntersecting: this.isIntersecting
				});
				if (!this.hasBackground || window.location.pathname === '/') {
					this.classList(this.headerClass).add(this.colorModifier);
					this.addColors();
				}
			} else {
				this.isIntersecting = entry.isIntersecting;
				this.events.trigger(document, 'isIntersecting', {
					isIntersecting: this.isIntersecting
				});
				this.classList(this.headerClass).remove(this.colorModifier);
				this.removeColors();
			}
		}
	}

	onContactIntersection(entries) {
		for (const entry of entries) {
			if (this.observedContactLine) {
				if (entry.isIntersecting === true) {
					this.contactLineOutersected = entry.isIntersecting;
					// this.events.trigger(document, 'isIntersecting', {isIntersecting: this.contactLineOutersected});
					this.classList(this.observedContactLine).add(this.stickyModifier);
				} else {
					// this.events.trigger(document, 'isIntersecting', {isIntersecting: this.contactLineOutersected});
					this.classList(this.observedContactLine).remove(this.stickyModifier);
				}
			}
		}
	}

	addColors() {
		for (let i = 0; i < this.iconClass.length; i++) {
			this.classList(this.iconClass[i]).add(this.svgColorModifier);
		}
	}

	removeColors() {
		for (let i = 0; i < this.iconClass.length; i++) {
			this.classList(this.iconClass[i]).remove(this.svgColorModifier);
		}
	}

	clear() {
		this.classList(this.headerClass).remove(this.colorModifier);
		if (this.observedClass !== null || this.observedContactLine !== null) {
			this.intersectionObserver.disconnect();
			this.contactIntersectionObserver.disconnect();
		}
		this.observedClass = null;
		this.observedContactLine = null;
	}
}

export default HeaderHandler;
