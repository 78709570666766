import XRegExp from 'xregexp';

// exception required for XregExp:
/*eslint-disable new-cap*/


class Rule {

	constructor() {
		this.last = false;
		this.matched = false;
		this.condition = null;
		this.conditionExtraParams = null;
		this.callback = null;
		this.callbackExtraParams = null;
		this.pattern = null;
		this.replacement = null;
		this.replacementCallback = null;
		this.matches = [];
		this.alwaysMatch = false;
	}


	always() {
		this.alwaysMatch = true;
		return this;
	}


	// 'index' and 'input' are reserved words used internally by XRegExp, so please do not use them
	// for named captures
	ifMatch(pattern, flags = '') {
		this.pattern = XRegExp(pattern, flags);
		return this;
	}


	ifTrue(condition, extraParams = []) {
		this.condition = condition;
		this.conditionExtraParams = extraParams;
		return this;
	}


	thenReplace(replacement) {
		this.replacement = replacement;
		return this;
	}


	thenReplaceCallback(replacementCallback) {
		this.replacementCallback = replacementCallback;
		return this;
	}


	then(callback, extraParams = []) {
		this.callback = callback;
		this.callbackExtraParams = extraParams;
		return this;
	}


	isLast() {
		return this.last;
	}


	setLast(value = true) {
		this.last = !!value;
		return this;
	}


	isMatched() {
		return this.matched;
	}


	match(subject, callbackExtraParams = []) {
		this.matched = false;
		if (this.alwaysMatch) {
			this.matched = true;
		} else {
			if (this.pattern === null && this.condition === null) {
				throw new Error('Require a string pattern or a callable condition');
			}

			if (this.pattern !== null) {
				this.matches = XRegExp.exec(subject, this.pattern, 0);
				this.matched = this.matches !== null;
			} else {
				this.matched = this.condition(subject, ...this.conditionExtraParams, ...callbackExtraParams);
			}
		}
		return this.matched;
	}


	replace(subject, callbackExtraParams = [], paramsTarget = {}) {
		if (this.replacement !== null) {
			subject = (this.pattern !== null ? XRegExp.replace(subject, this.pattern, this.replacement) : this.replacement);
		}

		if (this.replacementCallback !== null) {
			subject = (this.pattern !== null ? XRegExp.replace(subject, this.pattern, this.replacementCallback) : this.replacementCallback([]));
		}
		if (this.callback) {
			subject = this.callback(subject, this.matches, ...this.callbackExtraParams, ...callbackExtraParams);
		}

		if (this.matches !== null) {
			for (const key in this.matches) {
				if (this.matches.hasOwnProperty(key) && key !== 'index' && key !== 'input' && isNaN(key)) {
					paramsTarget[key] = this.matches[key];
				}
			}
		}

		return subject;
	}
}


export default Rule;
