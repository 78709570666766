import DefaultContext from './default-context';
import MenuContext from './menu-context';



export default (di) => {
	di
		.setType({type: DefaultContext, name: 'DefaultContext', parent: 'Context'})

		.setType({
			type: MenuContext, name: 'MenuContext', parent: 'Context',
			initCall: 'setEvents'
		})
		;
};
