import commonConfig from './common';

import animationConfig from '../animation/_config';
import appConfig from '../app/_config';
import cookiesConfig from '../cookies/_config';
import contentConfig from '../content/_config';
import contextConfig from '../context/_config';
import mediaConfig from '../media/_config';
import menuConfig from '../menu/_config';
import pageConfig from '../page/_config';


const config = (di) => {
	commonConfig(di);

	animationConfig(di);
	appConfig(di);
	cookiesConfig(di);
	contentConfig(di);
	contextConfig(di);
	mediaConfig(di);
	menuConfig(di);
	pageConfig(di);

	const prefix = 'wld-';
	const classPrefix = 'wld-js-';

	di
		.setValue('dom/dataAttrPrefix', prefix)
		.setValue('dom/classPrefix', classPrefix)
		.setValue('dom/baseClassPrefix', prefix)
		;

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');
	const apiUrl = bodyData.get('apiUrl');
	const registerUrl = bodyData.get('registerUrl');

	di
		.setValue('baseUrl', baseUrl)
		.setValue('baseThumbUrl', bodyData.get('baseThumbUrl'))
		.setValue('relPath', bodyData.get('relPath'))
		.setValue('projectUrls', bodyData.get('projectUrls'))
		.setValue('api/baseUrl', apiUrl)
		.setValue('account/registerUrl', registerUrl)
		.setValue('account/defaultUrl', bodyData.get('accountDefaultUrl'))
		.setValue('account/loggedDefaultUrl', bodyData.get('accountLoggedDefaultUrl'))

		// we keep the translated strings in a separated files
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		.setValue('locale/langs', window.wldLangs)
		.setValue('locale/defaultLanguage', 'de')

		// we keep the templates in a separated files
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		.setValue('template/tpls', window.frontendTpls)

		.setParam('PageLoader', 'baseUrl', baseUrl)
		.setParam('HashScroller', 'duration', 1)
		//.setSetter('PageLoader', 'injectRouter', di.lazyGet('router/router'))

		.setParam('WldApp', 'requiredIconFiles', bodyData.get('iconFiles'))

		.addPostCreateCall('Template', (template) => {
			template.registerHelpers({
				text: di.newInstance('LocaleHelper'),
				html: di.newInstance('HtmlHelper'),
				date: di.newInstance('DateHelper'),
				id: di.newInstance('IdHelper'),
				string: di.newInstance('StringHelper'),
				url: di.newInstance('UrlHelper'),
				thumb: di.newInstance('ThumbHelper')
			});
		})

		.addPostCreateCall('PageComponentFactory', (factory) => {
			factory.setAliases({
				Search: 'WldSearch',
				AccountManager: 'WldAccountManager'
			});
		})

		.setValue('menu/mediaQuery', '(max-width: 768px)')

		;
};


export default config;
