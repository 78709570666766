import picturefill from 'picturefill';
import objectFitImages from 'object-fit-images';
import imagesLoaded from 'imagesloaded';
import {waitCondition} from '../../common/utils/wait';
import PageComponent from '../component/page-component';


class AsyncPicture extends PageComponent {


    constructor({root, element}) {
        super({root: root, element: element});
        this.defaults.autoload = true;
        this.loading = false;
    }


    prepare(element, data) {
        this.openAsyncEvent('load');
        this.img = this.element.querySelector('img');
        if (data.get('autoload')) {
            this.load();
        }
    }


    load() {
        if (!this.loading) {
            this.loading = true;
            for (const node of this.element.querySelectorAll(this.dataSelector('srcset'))) {
                node.setAttribute('srcset', this.dataAttr(node).get('srcset'));
                this.dataAttr(node).remove('srcset');
            }
            picturefill({
                reevaluate: true,
                elements: [this.img]
            });

            objectFitImages(this.img);
            // IE does not support currentSrc
            const currentSrcSupported = this.img.currentSrc !== undefined;
            const condition = currentSrcSupported ?
                () => (this.img.currentSrc && this.img.currentSrc.length) :
                () => (this.img.src && this.img.src.length)
            ;
            waitCondition(condition).then(() => {
                const img = new Image();
                img.src = currentSrcSupported ? this.img.currentSrc : this.img.src;
                imagesLoaded([img], () => {
                    this.closeAsyncEvent('load');
                    this.events.trigger(this.element, 'picture:load', {component: this});
                });
            });
        }
        return this.on('load');
    }


    getImg() {
        return this.img;
    }

}

export default AsyncPicture;
