import PageComponent from '../../common/component/page-component';
// import httpRequest from 'superagent';


class ArticleListExtender extends PageComponent {

	constructor({
		root,
		baseUrl,
		element,
		visibilityModifier = 'invisible'
	} = {}) {
		super({root: root, element: element});
		this.json = '';
		this.updateFactor = 4;
		this.baseUrl = baseUrl;
		this.element = element;
		this.articleAmount = '';
		this.ownUrl = this.baseUrl;
		this.targetUrl = '/articles';
		this.XHR = new XMLHttpRequest();
		this.sendObj = {offset: 4, limit: 4};
		this.visibilityModifier = visibilityModifier;
	}

	prepare(element) {
		this.expandEvent = this.events.on(element, 'click', this.send.bind(this));
		this.articleList = document.querySelector(this.dataSelector('articleList'));
		this.showAll = this.dataAttr(this.articleList).get('articleListShowAll', false);
		this.pageUri = this.dataAttr(this.articleList).get('articleListPageUri', '');
		this.sendObj.showAll = this.showAll;
		this.sendObj.pageUri = this.pageUri;
	}

	send() {
		const articleData = JSON.stringify(this.sendObj);
		this.sendData(articleData);
	}

	sendData (data) {
		this.XHR.open('POST', this.ownUrl + this.targetUrl);
		this.XHR.setRequestHeader('Content-Type', 'application:json');
		this.XHR.send(data);

		this.XHR.onreadystatechange = () => {
			if (this.XHR.readyState === 4 && this.XHR.status === 200) {
				this.json = JSON.parse(this.XHR.responseText);
				this.appendJson();
			}
		};
	}

	appendJson() {
		this.articleList.insertAdjacentHTML('beforeend', this.json.articles);
		this.articleAmount = this.json.amount;
		this.events.trigger(document, 'extendArticles');

		if (this.json.offset + this.updateFactor < this.articleAmount) {
			this.updateJson();
		} else {
			this.classList(this.element).add(this.visibilityModifier);
			this.stop();
		}
	}

	updateJson() {
		this.sendObj.offset = this.json.offset + this.updateFactor;
	}

	stop() {
		this.expandEvent.destroy();
	}
}

export default ArticleListExtender;
