import {
	TweenMax
} from 'gsap';
import PageComponent from '../../common/component/page-component';


class Accordion extends PageComponent {

	constructor({
		context = null,
		selector = true,
		transitionDuration = 0.4,
		root,
		element,
		swapIconModifier = 'swap',
		colorModifier = 'colorSwap',
		borderModifier = 'border'
	} = {}) {
		super({
			root: root,
			element: element,
			context: context,
			selector: selector
		});
		this.element = element;
		this.openElements = [];
		this.colorModifier = colorModifier;
		this.borderModifier = borderModifier;
		this.swapIconModifier = swapIconModifier;
		this.transitionDuration = transitionDuration;
	}


	prepare(params = {}) {
		this.collapseListener = this.events.on(this.element, this.dataSelector('buttonMode', 'collapse'), 'click', this.onCollapseClick.bind(this));
	}


	clear() {
		this.collapseListener.destroy();
	}


	onCollapseClick(event, target) {
		if (event.target.closest('a[href]') !== null) {
			return;
		}
		const item = target.closest(this.dataSelector('accordion-item'));
		if (this.element.querySelector(this.dataSelector('icons')) !== null || this.element.querySelector(this.dataSelector('applicationWrapper')) !== null) {
			const icons = item.querySelectorAll(this.dataSelector('icons'));
			const colorClasses = item.querySelectorAll(this.dataSelector('applicationWrapper'));
			this.swapIcons(icons);
			this.swapColors(colorClasses);
		}


		const collapsed = this.dataAttr(item).get('collapsed');
		event.stopPropagation();
		if (collapsed) {
			this.openItem(item);
		} else {
			this.closeItem(item);
		}
		this.dataAttr(item).set('collapsed', !collapsed);
	}

	openItem(item) {
		const body = item.querySelector(this.dataSelector('accordionItemBody'));
		const currentHeight = body.offsetHeight;
		const content = body.querySelector(this.dataSelector('accordionItemContent'));
		const contentHeight = content.offsetHeight;
		this.classList(item).add('open');
		TweenMax.fromTo(body, this.transitionDuration, {
			height: currentHeight + 'px'
		}, {
			height: contentHeight + 'px',
			ease: 'Power2.easeOut',
			onComplete: () => {
				body.style.removeProperty('height');
			}
		});
	}


	closeItem(item) {
		const body = item.querySelector(this.dataSelector('accordionItemBody'));
		const currentHeight = body.offsetHeight;
		this.classList(item).remove('open');
		TweenMax.fromTo(body, this.transitionDuration, {
			height: currentHeight + 'px'
		}, {
			height: 0,
			ease: 'Power2.easeOut',
			onComplete: () => {
				body.style.removeProperty('height');
			}
		});
	}

	swapIcons(icons) {
		for (let i = 0; i < icons.length; i++) {
			const hasSwapIconModifier = this.classList(icons[i]).contains(this.swapIconModifier);
			if (hasSwapIconModifier) {
				this.classList(icons[i]).remove(this.swapIconModifier);
			}			else {
				this.classList(icons[i]).add(this.swapIconModifier);
			}
		}
	}

	swapColors(colorClasses) {
		for (let i = 0; i < colorClasses.length; i++) {
			const hasColorModifier = this.classList(colorClasses[i]).contains(this.colorModifier);
			if (hasColorModifier) {
				this.classList(colorClasses[i]).remove(this.colorModifier);
			}			else {
				this.classList(colorClasses[i]).add(this.colorModifier);
			}
		}
	}
}


export default Accordion;
