import Page from '../../common/page/page';


class DefaultPage extends Page {


	injectIntersectionTriggers(intersectionTriggers) {
		this.intersectionTriggers = intersectionTriggers;
	}


	onLoad() {
		const intersectionSelectors = [
			this.dataSelector('blockTile'),
			this.dataSelector('animateOnIntersection')
		];
		this.intersectionTriggers.init(this.element, intersectionSelectors);
		this.events.on(document, 'extendArticles', this.reInit.bind(this, intersectionSelectors));

		return super.onLoad();
	}


	onUnload() {
		this.intersectionTriggers.destroy();
		return super.onUnload();
	}

	reInit(intersectionSelectors) {
		this.intersectionTriggers.init(this.element, intersectionSelectors);
	}
}

export default DefaultPage;
