import domMixin from '../dom/dom-mixin';
import componentsMixin from '../component/components-mixin';
import contextsMixin from '../context/contexts-mixin';
import navigationMixin from '../navigation/navigation-mixin';


class App extends contextsMixin(componentsMixin(navigationMixin(domMixin()))) {
	constructor({root = document.body, requiredStyles = [], requiredFonts = [], requiredIconFiles = []} = {}) {
		super();
		this.root = root;
		this.requiredStyles = requiredStyles;
		this.requiredFonts = requiredFonts;
		this.requiredIconFiles = requiredIconFiles;

		this.fontsLoadedCallbacks = [];
		this.fontsLoadedCount = 0;
	}


	injectFontChecker(fontChecker) {
		this.fontChecker = fontChecker;
	}


	injectStyleChecker(styleChecker) {
		this.styleChecker = styleChecker;
	}


	injectIconFilesLoader(iconFilesLoader) {
		this.iconFilesLoader = iconFilesLoader;
	}


	injectPages(pages) {
		this.pages = pages;
	}


	init() {
		const promises = [];

		if (this.styleChecker) {
			for (const style of this.requiredStyles) {
				promises.push(this.styleChecker.checkStyle(style));
			}
		}

		const fontPromises = [];
		if (this.fontChecker) {
			for (const font of this.requiredFonts) {
				fontPromises.push(this.fontChecker.checkFont(font.name, font.options || {}));
			}
		}
		promises.push(Promise.all(fontPromises).then(() => this.processFontsCallbacks()));

		if (this.iconFilesLoader && this.requiredIconFiles.length) {
			promises.push(this.iconFilesLoader.load(this.requiredIconFiles));
		}

		this.components.init(this.root);
		return Promise.all(promises);
	}


	deinit() {}


	execute() {}


	addFontsLoadedCallback(callback) {
		this.fontsLoadedCallbacks.push(callback);
		return this;
	}


	processFontsCallbacks() {
		for (const callback of this.fontsLoadedCallbacks) {
			callback();
		}
		this.fontsLoadedCallbacks = [];
		return Promise.resolve();
	}

}


export default App;
