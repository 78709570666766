const templateMixin = (Base = null) => class extends Base {

	injectTemplate(template) {
		this.template = template;
	}

};


export default templateMixin;
