import AppLoader from './app-loader';
import VideoOverlayHandler from  './video-overlay-handler';
import HeaderHandler from  './header-handler';
import Slider from  './slider';
import Accordion from './accordion';



export default (di) => {
	di
		.setType({type: AppLoader, name: 'AppLoader', parent: 'PageComponent'})
		.setType({type: VideoOverlayHandler, name: 'VideoOverlayHandler', parent: 'PageComponent'})
		.setType({type: HeaderHandler, name: 'HeaderHandler', parent: 'PageComponent', params: {
			events: di.lazyGet('events/events')
		}})
		.setType({type: Slider, name: 'Slider', parent: 'PageComponent'})
		.setType({type: Accordion, name: 'Accordion', parent: 'PageComponent'})
	;
};
