import SwapTransition from './swap-transition';
import SwapTransitionFactory from './swap-transition-factory';

// import LoaderAnimation from './loader-animation';
import ParallaxBackgrounds from './parallax-backgrounds';
import IntersectionTriggers from './intersection-triggers';


export default (di) => {
	di
		.setType({type: SwapTransition, name: 'SwapTransition', mixins: ['domMixin']})

		.setType({type: SwapTransitionFactory, name: 'SwapTransitionFactory', parent: 'Factory'})
		.set('animation/swapTransitionFactory')

		// .setType({type: LoaderAnimation, name: 'LoaderAnimation', parent: 'PageComponent'})
		.setType({type: ParallaxBackgrounds, name: 'ParallaxBackgrounds', mixins: ['domMixin']})
		.setType({type: IntersectionTriggers, name: 'IntersectionTriggers', mixins: ['domMixin']})
	;
};
