import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import PageTransition from '../../common/page/page-transition';


class DefaultPageTransition extends PageTransition {

	constructor({pageLoader, enteringClass = 'entering', leavingClass = 'leaving', beforeLeavingClass = 'beforeLeaving', scrollDuration = 0, scrollDelay = 0}) {
		super({pageLoader: pageLoader});
		this.enteringClass = enteringClass;
		this.leavingClass = leavingClass;
		this.scrollDuration = scrollDuration;
		this.scrollDelay = scrollDelay;
		this.beforeLeavingClass = beforeLeavingClass;
	}


	beginAnimation(request) {
		this.classList(this.slots.get('default').getCurrentPage().getElement()).add(this.beforeLeavingClass);
		return Promise.resolve(request);
	}


	attachNewPage(newPage, oldPage, request, response) {
		super.attachNewPage(newPage, oldPage, request, response);
		if (oldPage) {
			this.classList(oldPage.getElement()).add(this.leavingClass);
		}
	}


	swapPages(newPage, oldPage, request, response) {
		if (newPage && oldPage) {
			const newElement = newPage.getElement();
			const oldElement = oldPage.getElement();
			const parent = oldElement.parentNode;
			const height = Math.max(newElement.getBoundingClientRect().height, oldElement.getBoundingClientRect().height);
			parent.style.height = height + 'px';
			this.classList(newElement).add(this.enteringClass);
			const scrollPos = (request.state && ('scrollTop' in request.state)) ? request.state.scrollTop : 0;
			const oldElementPromise = this.onTransitionEnd(oldElement);
			TweenMax.to(window, this.scrollDuration, {delay: this.scrollDelay, scrollTo: {y: scrollPos, autoKill: false}});
			return oldElementPromise.then(() => {
				parent.style.removeProperty('height');
				this.classList(newElement).remove(this.enteringClass);
				this.events.trigger(document, 'pageChange:done');
			});
		}
		return Promise.resolve();
	}

}


export default DefaultPageTransition;
