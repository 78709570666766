import 'intersection-observer';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';
// import 'picturefill/src/plugins/mutation/pf.mutation';
import App from '../../common/app/app';


class WldApp extends App {

	injectCurrentLinkManager(currentLinkManager) {
		this.currentLinkManager = currentLinkManager;
	}


	injectNavigationTracker(tracker) {
		this.tracker = tracker;
	}

	injectHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}


	injectPageSlots(pageSlots) {
		this.pageSlots = pageSlots;
	}


	init() {
		svg4everybody();
		picturefill();
		this.contexts.setDefaultContext('default', false);
		return super.init().then(() => {
			this.loader = this.getComponent('appLoader');
		});
	}


	execute() {
		const start = () => {
			this.contexts.getContext('default').activate();
			this.currentLinkManager.update();
			const hash = location.hash;
			if (hash.length) {
				this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
			}
		};

		this.pages.initCurrent().then(() => {
			if (this.loader) {
				this.loader.hide().then(start);
			} else {
				start();
			}
		});
	}

}

export default WldApp;
