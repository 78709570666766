import PageComponent from '../component/page-component';


class ExternalVideo extends PageComponent {

	constructor({
		element,
		root,
		playerDataSelector = 'externalVideoPlayer',
		buttonDataSelector = 'externalVideoBtn',
		visibleClass = 'visiblePlayer'
	} = {}) {
		super({element: element, root: root});
		this.playerDataSelector = playerDataSelector;
		this.buttonDataSelector = buttonDataSelector;
		this.visibleClass = visibleClass;
		this.videoVisible = false;
	}


	prepare(element, data) {
		this.listeners.click = this.events.on(this.element, this.dataSelector(this.buttonDataSelector), 'click', this.onClick.bind(this));
		this.player = element.querySelector(this.dataSelector(this.playerDataSelector));
	}


	onClick(event) {
		console.log('click');
		if (event.button === 0 && !event.ctrlKey && !event.metaKey) {
			event.preventDefault();
			if (!this.videoVisible) {
				this.player.innerHTML = this.dataAttr(this.player).get(this.playerDataSelector);
				this.classList(this.element).add(this.visibleClass);
				this.videoVisible = true;
			}
		}
	}
}


export default ExternalVideo;
