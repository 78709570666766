import PageComponent from '../../common/component/page-component';


class Slider extends PageComponent {

	constructor({
		root,
		element,
		dotAttribute = 'dots',
		slideAttribute = 'slides',
		selectedClass = 'selected',
		duration = 10
	} = {}) {
		super({
			root: root,
			element: element
		});
		this.dotNodes = [];
		this.slideNodes = [];
		this.dotAttribute = dotAttribute;
		this.slideAttribute = slideAttribute;
		this.counter = 0;
		this.defaults.duration = duration;
		this.duration = 0;
		this.timeout = null;
		this.slideAmount = 0;
		this.clicked = false;
		this.selectedClass = selectedClass;
	}

	prepare(element, data) {
		this.duration = parseInt(data.get('duration'), 10);
		this.slideNodes = this.element.querySelectorAll(this.dataSelector(this.slideAttribute));
		this.dotNodes = this.element.querySelectorAll(this.dataSelector(this.dotAttribute));
		this.slideAmount = this.slideNodes.length;
		this.listeners.dot = this.events.on(element, this.dataSelector(this.dotAttribute), 'click', this.onClick.bind(this));

		this.listeners.swipe = this.events.on(this.element, 'dragleft dragright swipeleft swiperight', this.onSwipe.bind(this));
		this.startCarousel();
	}

	onSwipe(event) {
		event.preventDefault();
		if (event.gesture.offsetDirection === this.events.gestures.DIRECTION_RIGHT ) {
			this.previousSlide();
		} else {
			this.nextSlide();
		}
	}

	swapSlides(previousIndex, nextIndex) {
		return this.swapNodes(this.slideNodes, previousIndex, nextIndex);
	}

	swapDots(previousIndex, nextIndex) {
		return this.swapNodes(this.dotNodes, previousIndex, nextIndex);
	}

	swapNodes(nodeList, previousIndex, nextIndex) {
		this.classList(nodeList[previousIndex]).remove(this.selectedClass);
		this.classList(nodeList[nextIndex]).add(this.selectedClass);
		return this.onTransitionEnd(nodeList[nextIndex]);
	}

	startCarousel() {
		if (this.duration > 0) {
			this.clearCarousel();
			this.timeout = setTimeout(() => {
				this.nextSlide();
			}, this.duration * 1000);
		}
	}

	clearCarousel() {
		if (this.timeout) {
			clearTimeout(this.timeout);
			this.timeout = null;
		}
	}


	nextSlide() {
		this.goTo((this.counter + 1) % this.slideAmount);
	}


	previousSlide() {
		this.goTo(this.counter === 0 ? this.slideAmount - 1 : this.counter - 1);
	}


	goTo(index) {
		if (!this.clicked) {
			this.clicked = true;
			this.swapDots(this.counter, index);
			this.swapSlides(this.counter, index)
			.then(() => {
				this.counter = index;
				this.clicked = false;
				this.startCarousel();
			});
		}
	}

	onClick(event, target) {
		const index = this.dataAttr(target).get(this.dotAttribute);
		this.goTo(index);
	}

	clear() {
		this.clearCarousel();
	}
}

export default Slider;
