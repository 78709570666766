// import {getScrollTop} from 'get-scroll';
import 'intersection-observer';
import PageComponent from '../../common/component/page-component';

class Logo extends PageComponent {

	constructor({root, element, intersectionClassVisible = 'visible'}) {
		super({root: root, element: element});
		this.intersectionClassVisible = intersectionClassVisible;
	}

	prepare(element, data) {
		this.observedClass = document.querySelector(this.dataSelector('observed-class'));
		this.targetClassBase = document.querySelector(this.dataSelector('logo-base-color'));
	}

	start() {
		this.observerInit();
	}

	observerInit() {
		this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this));

		this.intersectionObserver.observe(this.observedClass);
	}

	onIntersection(entries) {
		for (const entry of entries) {
			if (entry.isIntersecting === false) {
				this.classList(this.targetClassBase).add(this.intersectionClassVisible);
			} else {
				this.classList(this.targetClassBase).remove(this.intersectionClassVisible);
			}
		}
	}

	stop() {
		this.intersectionObserver.disconnect();
		this.observedClass = null;
		this.targetClassBase = null;
	}
}

export default Logo;
