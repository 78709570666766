import PageComponent from '../../common/component/page-component';

class AppLoader extends PageComponent {

    hide(options = {}) {
		this.classList(this.root).remove('noscroll');
        return Promise.resolve(this.element);
		// if (!this.classList(this.element).contains('loading')) {
		// 	return Promise.resolve(this.element);
        // }
		// this.classList(this.element).remove('loading', 'cover');
		// return this.onTransitionEnd(this.element);
    }

}

export default AppLoader;
