import PageComponent from '../../common/component/page-component';


class AsyncVideo extends PageComponent {


    constructor({root, element}) {
        super({root: root, element: element});
    }


    prepare(element, data) {
		const embed = this.data.get('videoEmbed', null);
		if (embed) {
			this.element.innerHTML = embed;
		}
    }

}

export default AsyncVideo;
