import ArticleListExtender from './article-list-extender';



export default (di) => {
	di
		.setType({type: ArticleListExtender, name: 'ArticleListExtender', parent: 'PageComponent', params: {
			baseUrl: di.lazyValue('baseUrl')
		}})
	;
};
