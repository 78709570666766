import PageComponent from '../../common/component/page-component';

class VideoOverlayHandler extends PageComponent {

	constructor({
		root,
		element,
		overlayModifier = 'hidden',
		bgColorModifier = 'black',
		colorModifier = 'invertedColor',
		svgColorModifier = 'invertedSvgColor',
		fadeDelay = 2000,
		bodyModifier = 'noScroll'
	} = {}) {
		super({
			root: root,
			element: element
		});
		this.element = element;
		this.fadeDelay = fadeDelay;
		this.bodyModifier = bodyModifier;
		this.colorModifier = colorModifier;
		this.bgColorModifier = bgColorModifier;
		this.overlayModifier = overlayModifier;
		this.svgColorModifier = svgColorModifier;
	}

	prepare(element, data) {
		this.overlayClass = document.querySelector(this.dataSelector('video-overlay'));
		this.iconClass = document.querySelectorAll(this.dataSelector('icon-base-color'));
		this.body = document.querySelector(this.dataSelector('body'));
		this.html = document.querySelector('html');
		this.startCondition = document.body.querySelector(this.dataSelector('start-condition'));
		this.addColorChangeClass();
	}

	start(first) {
		if (first) {
			const startCondition = this.dataAttr(document.body).get('start-condition', false);
			if (startCondition) {
				this.fadeTimer()
				.then((resolve) => {
					if (this.finished === true) {
						this.finished = {
							state: true
						};
						this.hideOverlay();
						this.toggleScroll();
					}
				});
			} else {
				this.hideOverlay();
				this.removeColorChangeClass();
			}
		}
	}


	hideOverlay() {
		this.classList(this.overlayClass).add(this.bgColorModifier);
		setTimeout(() => {
			this.classList(this.overlayClass).add(this.overlayModifier);
		}, 200);
		this.onTransitionEnd(this.overlayClass).then(() => {
			this.dataAttr(document.body).set('start-condition', false);
		});
	}


	toggleScroll() {
		this.classList(this.body).remove(this.bodyModifier);
	}

	fadeTimer() {
		return new Promise((resolve) => {
			this.timer = setTimeout(() => {
				this.removeColorChangeClass();
				resolve(this.finished = true);
			}, this.fadeDelay);
		});
	}

	addColorChangeClass() {
		for (let i = 0, max = this.iconClass.length; i < max; i += 1) {
			const ModifierSvgExists = this.classList(this.iconClass[i]).contains(this.svgColorModifier);
			if (!ModifierSvgExists) {
				this.classList(this.iconClass[i]).add(this.svgColorModifier);
			}
		}
	}

	removeColorChangeClass() {
		for (let i = 0, max = this.iconClass.length; i < max; i += 1) {
			console.log(this.iconClass[i]);
			this.classList(this.iconClass[i]).remove(this.svgColorModifier);
		}
	}


	stop() {
		// window.clearTimeout(this.timer);
		this.destroyListeners();
	}
}

export default VideoOverlayHandler;
